import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { globalHistory } from "@reach/router";

export const AppContext = createContext({});

const AppProvider = ({ children }) => {
  const [cursorBackground, setCursorBackground] = useState(`blue`);
  const [cursorMode, setCursorMode] = useState(null);
  const [cursorVisible, setCursorVisible] = useState(false);
  const [headerColor, setHeaderColor] = useState(`white`);
  const [headerText, setHeaderText] = useState(null);
  const [workLink, setWorkLink] = useState(null);
  const [menuActive, setMenuActive] = useState(false);
  const [notificationActive, setNotificationActive] = useState(false);
  const [pathname, setPathname] = useState(null);
  const [wipModalActive, setWipModalActive] = useState(false);

  //

  useEffect(() => {
    if (window) {
      setPathname(window.location.pathname);
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  // useEffect(() => {
  //   if (!pathname) {
  //     return;
  //   }

  //   if (pathname === `/about`) {
  //     setTimeout(() => {
  //       setCursorBackground(`white`);
  //     });
  //   } else {
  //     setTimeout(() => {
  //       setCursorBackground(`red`);
  //     });
  //   }
  // }, pathname);

  //

  return (
    <AppContext.Provider
      value={{
        cursorBackground,
        setCursorBackground,
        cursorMode,
        setCursorMode,
        cursorVisible,
        setCursorVisible,
        headerColor,
        setHeaderColor,
        headerText,
        setHeaderText,
        menuActive,
        setMenuActive,
        notificationActive,
        setNotificationActive,
        pathname,
        wipModalActive,
        workLink,
        setWorkLink,
        setWipModalActive
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AppProvider;
